import React from 'react';
import PropTypes from 'prop-types';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './PartnerFeatures.module.css';

import Button from '../../../../elements/Button';
import Container from '../../../../blocks/Container';
import FullWidthRow from '../../../../blocks/FullWidthRow';
import Text from '../../../../elements/Text';
import Title from '../../../../elements/Title';
import Image from '../../../../elements/Image';


const PartnerFeatures = ({ ctaUrl }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/partner-features.md/" } }) {
        edges {
          node {
            frontmatter {
              statements {
                pretitle
                title
                description
                type
                image
              }
            }
          }
        }
      }
    }
  `);

  const { statements } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.partnerFeatures}>
      <Container>
        <Container type="mobile">
          <div className="row">
            {
              statements.map((statement, index) => (
                <div key={index} className={`${styles.partnerFeatures__feature} col-xl-6 col-lg-6 col-12 order-xl-0 order-lg-0 order-0`}>
                  <Image name={statement.image} className={`${styles.partnerFeatures__image}`}/>
                  <Title type="small" className={`${styles.partnerFeatures__title}`}>{statement.title}</Title>
                  <Text className={`${styles.partnerFeatures__desc}`}>
                    {statement.description}
                  </Text>
                </div>
              ))
            }
          </div>
          <FullWidthRow className={styles.partnerFeatures__bottomButtonMargin}>
            <div className="d-flex justify-content-center mt-5">
              <Button type="action" href={ctaUrl}>Yes, start my trial!</Button>
            </div>
          </FullWidthRow>
        </Container>
      </Container>
    </div>
  )
};

PartnerFeatures.propTypes = {
  ctaUrl: PropTypes.string.isRequired
};

export default PartnerFeatures;