import React from 'react';
import PropTypes from 'prop-types';

import styles from './Text.module.css';

const Text = ({ children, className }) => (
  <div className={`${styles.text} ${className}`}>
    {children}
  </div>
);

Text.defaultProps = {
  className: ''
};

Text.propsTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Text;
