import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const WistiaEmbed = ({ hashedId, children, type }) => {

  useEffect(() => {
    let handledVideo;
    const wistiaScript = document.createElement('script');
    wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    wistiaScript.async = true;
    wistiaScript.dataset.cookieconsent  = "ignore";
    document.body.appendChild(wistiaScript);

    window._wq = window._wq || [];
    window._wq.push({
      id: hashedId,
      onEmbedded: video => handledVideo = video
    });

    return () => {
      if (handledVideo) handledVideo.remove();
    }
  }, [hashedId]);

  if (type === 'inline') {
    return (
      <div
        className="wistia_responsive_padding"
        style={{ padding: '56.25% 0 0 0', position: 'relative' }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%'
          }}
        >
          <div
            className={`wistia_embed wistia_async_${hashedId} videoFoam=true`}
            style={{ height: '100%', position: 'relative', width: '100%' }}
          >
            <div
              className="wistia_swatch"
              style={{
                height: '100%',
                left: 0,
                opacity: 0,
                overflow: 'hidden',
                position: 'absolute',
                top: 0,
                transition: 'opacity 200ms',
                width: '100%'
              }}
            >
              <img
                src={`https://fast.wistia.com/embed/medias/${hashedId}/swatch`}
                style={{
                  filter: 'blur(5px)',
                  height: '100%',
                  objectFit: 'contain',
                  width: '100%'
                }}
                alt=""
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <span className={`wistia_embed wistia_async_${hashedId} popover=true popoverContent=link`}>
      {children}
    </span>
  )

};

WistiaEmbed.propTypes = {
  hashedId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['inline', 'default']),
  children: PropTypes.node
};

WistiaEmbed.defaultProps = {
  type: 'default',
  children: null
};

export default WistiaEmbed;