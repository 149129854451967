import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Header from '../Header';
import Footer from '../Footer';
import AnnouncementBar from '../AnnouncementBar';

import styles from './Layout.module.css';

const Layout = ({ children, ctaUrl, noHeader }) => {
  const announcementBar = useRef(null);

  const [announcementsExists, updateAnnouncementsExists] = useState(false);

  const [announcementsHeight, updateAnnouncementsHeight] = useState(0);

  const adjustAnnouncementBarHeight = () => {
    if (announcementBar.current) {
      for (let announcement of announcementBar.current.children) {
        if (announcement.clientHeight > announcementsHeight)
          updateAnnouncementsHeight(announcement.clientHeight);
      }
    }
  };

  const onChangeAnnouncementsListLength = (length) => {
    setTimeout(() => adjustAnnouncementBarHeight(), 500);
    updateAnnouncementsExists(length > 0);
  };

  return (
    <Fragment>
      <AnnouncementBar
        ref={announcementBar}
        onChangeAnnouncementsListLength={onChangeAnnouncementsListLength}
      />
      {!noHeader && (
        <Header
          withAnnouncementBar={announcementsExists}
          announcementsHeight={announcementsHeight}
          ctaUrl={ctaUrl}
        />
      )}
      <div
        className={`${styles.content}`}
        style={
          announcementsExists ? { top: `${56 + announcementsHeight}px` } : {}
        }
      >
        <main>{children}</main>
        <Footer />
      </div>
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  ctaUrl: PropTypes.string,
  noHeader: PropTypes.bool,
};

export default Layout;
