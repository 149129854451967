import React from 'react';
import PropTypes from 'prop-types';

const FullWidthRow = ({ children, className }) => (
  <div className={`row ${className}`}>
    <div className="col-12 on-top">
      {children}
    </div>
  </div>
);

FullWidthRow.defaultProps = {
  className: ''
};

FullWidthRow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default FullWidthRow;