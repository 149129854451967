import React from 'react';
import PropTypes from 'prop-types';

import styles from './PartnerHeader.module.css';

import Title from '../../../../elements/Title';
import Container from '../../../../blocks/Container';
import FullWidthRow from '../../../../blocks/FullWidthRow';
import Image from '../../../../elements/Image';
import WistiaEmbed from '../../../../elements/WistiaEmbed';

const PartnerHeader = ({ partner }) => (
  <div className={styles.partnerHeader}>
    <Container>
      <Container type="mobile">
        <FullWidthRow className="mb-4">
          {
            partner.specialTitle ?
              <Title type="white"><span dangerouslySetInnerHTML={{ __html: partner.specialTitle}} /></Title> :
              <Title type="white">A Special Message... And A Special Offer <br />From {partner.name}</Title>
          }
        </FullWidthRow>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12 order-xl-1 order-lg-0 order-0 d-flex flex-column align-items-md-center h-100">
            <div className={`${styles.partnerHeader__video}`}>
              <WistiaEmbed type="inline" hashedId={partner.wistiaHash} />
            </div>
            <Image name="guy" className={`${styles.partnerHeader__guy}`} />
            <Image name="girl" className={`${styles.partnerHeader__girl}`} />
          </div>
        </div>
      </Container>
    </Container>
    <div className={styles.partnerHeader__clouds} />
  </div>
);

PartnerHeader.propTypes = {
  partner: PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    wistiaHash: PropTypes.string.isRequired,
    ctaUrl: PropTypes.string.isRequired,
    ctaText: PropTypes.string,
    audienceDescription: PropTypes.string
  }).isRequired
};

export default PartnerHeader;
