import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import styles from './MobileMenu.module.css';

import Button from '../../../elements/Button';
import Image from '../../../elements/Image';

const MobileMenu = ({ show, navigationItems, withAnnouncementBar, announcementsHeight, ctaUrl }) => {

  const howItWorkNavigationItem = navigationItems[1];

  useEffect(() => {
    const menu = document.getElementById('menu-container');
    if (show) {
      disableBodyScroll(menu)
    } else {
      enableBodyScroll(menu)
    }
  }, [show]);

  return (
    <div
      id="menu-container"
      className={`
        ${styles.mobileMenu} 
        ${show ? styles.mobileMenuShow : ''} 
      `}
      style={withAnnouncementBar ? { top: `${55 + announcementsHeight}px` } : {}}
    >
      <div className={styles.mobileMenuContent}>
        <div className={styles.mobileMenuContent__top}>
          <div className={styles.mobileMenu__item}>{howItWorkNavigationItem.title}</div>
          {
            howItWorkNavigationItem.dropdownItems.map(dropdownItem => (
              <a key={dropdownItem.title} className={styles.mobileMenu__submenuItem} href={dropdownItem.href}>
                {
                  dropdownItem.image &&
                  <div style={{ width: '46px', height: '46px' }}>
                    <Image
                      name={dropdownItem.image}
                      className={`
                        ${styles.mobileMenu__submenuItemImage} 
                        ${dropdownItem.image === 'features_icon' ? styles.mobileMenu__submenuItemImageFeatures : ''}
                      `}
                    />
                  </div>
                }
                <div className={styles.mobileMenu__submenuItemText}>
                  <div className={styles.mobileMenu__submenuItemTitle}>{dropdownItem.title}</div>
                  {dropdownItem.description}
                </div>
              </a>
            ))
          }
        </div>
        <div className={styles.mobileMenuContent__divider} />
        <div className={styles.mobileMenuContent__bottom}>
          <div className="d-flex flex-column align-items-start mb-3">
            {
              navigationItems.filter(item => item.title !== 'How It Works').map((navigationItem, index) => (
                <Fragment key={index}>
                  {
                    navigationItem.href &&
                    <a href={navigationItem.href} className={styles.mobileMenu__item}>{navigationItem.title}</a>
                  }
                  {
                    navigationItem.dropdownItems &&
                    navigationItem.dropdownItems.map((subItem, index) => (
                      <a key={index} href={subItem.href} className={styles.mobileMenu__item}>{subItem.title}</a>
                    ))
                  }
                </Fragment>
              ))
            }
          </div>
          <div className="d-flex flex-column w-100">
            <Button
              type="secondary"
              className={styles.mobileMenu__button}
              href={`${process.env.APP_URL}/login`}
            >
              Log in
            </Button>
            <Button
              type="primary"
              className={`${styles.mobileMenu__button} mt-3`}
              href={ctaUrl || 'https://start.deadlinefunnel.com/create-monthly'}
            >
              Try it free
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
};

MobileMenu.propTypes = {
  show: PropTypes.bool.isRequired,
  navigationItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: PropTypes.string,
    dropdownItems: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string,
      href: PropTypes.string.isRequired
    })),
    additionalDropdownItems: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    }))
  })).isRequired,
  withAnnouncementBar: PropTypes.bool.isRequired,
  announcementsHeight: PropTypes.number.isRequired,
  ctaUrl: PropTypes.string
};

export default MobileMenu;