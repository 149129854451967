import React from 'react';
import PropTypes from 'prop-types'

import styles from './Button.module.css';

import { capitalize } from '../../../utils';

const Button = ({ type, className, children, href, target }) => (
  <a
    href={href}
    target={target}
    rel="noopener noreferrer"
    className={`${styles.button} ${styles[`button${capitalize(type)}`]} ${className}`}
  >
    {children}
  </a>
);

Button.defaultProps = {
  className: '',
  href: ''
};

Button.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary', 'action', 'start', 'info']).isRequired
};

export default Button;