import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';

import styles from './Footer.module.css';

import Container from '../Container';

const navigationItems = [
  { title: 'features', href: '/features' },
  { title: 'integrations', href: '/integrations' },
  { title: 'case studies', href: '/case-studies' },
  { title: 'pricing', href: '/pricing' },
  { title: 'blog', href: 'https://blog.deadlinefunnel.com' },
  { title: 'log in', href: `${process.env.APP_URL}/login` },
];

const supportItems = [
  {
    title: 'documentation',
    href: 'https://docs.deadlinefunnel.com'
  },
  { title: 'privacy & terms', href: '/tos' },
  {
    title: 'status',
    href: 'https://status.deadlinefunnel.com'
  },
  { title: 'gdpr', href: '/gdpr' },
  { title: 'cookies', href: '/cookies' },
];

const followUsItems = [
  {
    title: 'facebook',
    icon: faFacebookF,
    href: 'https://facebook.com/deadlinefunnel'
  },
  {
    title: 'twitter',
    icon: faTwitter,
    href: 'https://twitter.com/deadlinefunnel'
  }
];

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerInfo}>
        <Container type="big">
          <Container type="mobile">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-3 col-12 d-flex flex-column align-items-xl-start align-items-lg-start align-items-md-start align-items-start">
                <div className={styles.footerInfo__blockContainer}>
                  <div className={styles.footerInfo__title}>
                    Contact Us
                  </div>
                  <div className={styles.footerInfo__description}>
                    303B Anastasia Blvd. #163
                    Saint Augustine, FL 32080
                    904.270.9320
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-7 d-flex flex-column align-items-start">
                <div className={styles.footerInfo__blockContainer}>
                  <div className={styles.footerInfo__title}>
                    Product
                  </div>
                  <div className={`${styles.footerInfo__description} d-flex flex-column flex-wrap`}>
                    {navigationItems.map((navigationItem, index) => (
                      <a key={index} href={navigationItem.href}>{navigationItem.title}</a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-5 d-flex flex-column align-items-start">
                <div className={styles.footerInfo__blockContainer}>
                  <div className={styles.footerInfo__title}>
                    Support
                  </div>
                  <div className={`${styles.footerInfo__description} d-flex flex-column flex-wrap`}>
                    {supportItems.map((supportItem, index) => (
                      <a key={index} href={supportItem.href}>{supportItem.title}</a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-12 d-flex flex-column align-items-start">
                <div className={styles.footerInfo__blockContainer}>
                  <div className={styles.footerInfo__title}>
                    Follow Us
                  </div>
                  <div className={`${styles.footerInfo__description} d-flex flex-column flex-wrap`}>
                    {
                      followUsItems.map((followUsItem, index) => (
                        <a key={index} href={followUsItem.href} target="_blank" rel="noopener noreferrer">
                        <span className={`${styles.footerInfo__followIconContainer} mr-2`}>
                          <FontAwesomeIcon icon={followUsItem.icon} />
                        </span>
                          {followUsItem.title}
                        </a>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Container>
      </div>
      <div className={styles.footerCopyrights}>Copyright © {(new Date().getFullYear())} Smart Funnel Software LLC</div>
    </footer>
  )
};

export default Footer;