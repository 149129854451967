import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import styles from './Navigation.module.css';

import Image from '../../../elements/Image';

const Navigation = ({ navigationItems }) => {
  return (
    <div className={styles.navigation}>
      {
        navigationItems.map(navigationItem => (
          <Fragment key={navigationItem.title}>
            <a className={styles.navigation__item} href={navigationItem.href || '#'}>
              {navigationItem.title}
              {
                !navigationItem.href &&
                <FontAwesomeIcon className="ml-2" icon={faChevronDown} size="sm" style={{ maxWidth: '10px' }} />
              }
              {
                navigationItem.dropdownItems &&
                <div className={styles.navigation__submenuContainerAfter} />
              }
              {
                navigationItem.dropdownItems &&
                <div
                  className={`
                    ${styles.navigation__submenuContainer} 
                    ${navigationItem.dropdownItems.some(item => item.image) ? styles.navigation__submenuContainerWithImages : ''}
                  `}
                >
                  <div className={styles.navigation__submenu}>
                    {
                      navigationItem.dropdownItems.map(dropdownItem => (
                        <a key={dropdownItem.title} className={styles.navigation__submenuItem} href={dropdownItem.href}>
                          {
                            dropdownItem.image &&
                            <div style={{ width: '46px', height: '46px' }}>
                              <Image
                                name={dropdownItem.image}
                                className={`
                                  ${styles.navigation__submenuItemImage} 
                                  ${dropdownItem.image === 'features_icon' ? styles.navigation__submenuItemImageFeatures : ''}
                                `}
                              />
                            </div>
                          }
                          <div className="d-flex flex-column">
                            <div className={styles.navigation__submenuItemTitle}>{dropdownItem.title}</div>
                            {dropdownItem.description}
                          </div>
                        </a>
                      ))
                    }
                  </div>
                  {
                    navigationItem.additionalDropdownItems &&
                    navigationItem.additionalDropdownItems.map(additionalItem => (
                      <a
                        key={additionalItem.title}
                        className={`${styles.navigation__submenuItem} ${styles.navigation__submenuItemAdditionalItem}`}
                        href={additionalItem.href.replace('{{appUrl}}', process.env.APP_URL)}
                      >
                        <div className="d-flex w-100 justify-content-between align-items-center">
                          <div className="d-flex flex-column">
                            <div className={styles.navigation__submenuItemTitle}>{additionalItem.title}</div>
                            {additionalItem.description}
                          </div>
                          <Image name="navigation_chevron_right" className={styles.navigation__chevronRight} />
                          {/*<FontAwesomeIcon icon={faChevronRight} size="lg" />*/}
                        </div>
                      </a>
                    ))
                  }
                </div>
              }
            </a>
          </Fragment>
        ))
      }
    </div>
  )
};

Navigation.propTypes = {
  navigationItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: PropTypes.string,
    dropdownItems: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string,
      href: PropTypes.string.isRequired
    })),
    additionalDropdownItems: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    }))
  })).isRequired
};

export default Navigation;