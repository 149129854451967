import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './Header.module.css';

import Logo from '../../../images/svg/logo.svg';
import Button from '../../elements/Button';
import Navigation from './Navigation';
import MobileMenu from './MobileMenu';
import Container from '../Container';

const Header = ({ withAnnouncementBar, announcementsHeight, ctaUrl }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/navigation-items.md/" } }) {
        edges {
          node {
            frontmatter {
              navigationItems {
                title
                href
                dropdownItems {
                  title
                  description
                  image
                  href
                }
                additionalDropdownItems {
                  title
                  description
                  href
                }
              }
            }
          }
        }
      }
    }
  `);

  const { navigationItems } = data.allMarkdownRemark.edges[0].node.frontmatter;

  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

  return (
    <header className={styles.header} style={withAnnouncementBar ? { top: announcementsHeight } : {}}>
      <Container className={styles.header__mobileContainer}>
        <div className="row align-items-center">
          <div className="col-3">
            <a href="/" className="cursor-pointer" aria-label="To the main page"><Logo /></a>
          </div>
          <nav className="col-6 d-xl-flex d-lg-flex d-none justify-content-end">
            <Navigation navigationItems={navigationItems} />
          </nav>
          <div className="col-3 d-xl-flex d-lg-flex d-none justify-content-end p-0">
            <Button type="secondary" className="mr-2" href={`${process.env.APP_URL}/login`}>Log in</Button>
            <Button type="primary" href={ctaUrl || 'https://start.deadlinefunnel.com/create-monthly'}>Sign up</Button>
          </div>
          <div className="col-9 d-xl-none d-lg-none justify-content-end align-items-center">
            <div
              className={styles.mobileMenuIcon}
              onClick={() => setMobileMenuOpened(!mobileMenuOpened)}
              onKeyDown={() => setMobileMenuOpened(!mobileMenuOpened)}
              role="button"
              tabIndex={0}
            >
              {
                mobileMenuOpened
                  ? <div className={styles.mobileMenuIcon__closeIcon}>
                    &times;
                  </div>
                  : <Fragment>
                    <div className={styles.mobileMenuIcon__line} />
                    <div className={styles.mobileMenuIcon__line} />
                    <div className={styles.mobileMenuIcon__line} />
                  </Fragment>
              }
            </div>
          </div>
        </div>
      </Container>
      <MobileMenu
        withAnnouncementBar={withAnnouncementBar}
        announcementsHeight={announcementsHeight}
        show={mobileMenuOpened}
        navigationItems={navigationItems}
      />
    </header>
  )
};

Header.propTypes = {
  withAnnouncementBar: PropTypes.bool.isRequired,
  announcementsHeight: PropTypes.number.isRequired,
  ctaUrl: PropTypes.string
};

export default Header;