import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './Impact.module.css';

import PropTypes from 'prop-types';

import Container from '../../../../blocks/Container';
import Title from '../../../../elements/Title';
import FullWidthRow from '../../../../blocks/FullWidthRow';
import Button from '../../../../elements/Button';

const Impact = ({ partner, ctaUrl }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/help-creators-text-blocks.md/" } }) {
        edges {
          node {
            frontmatter {
              blocks
            }
          }
        }
      }
    }
  `);

  const { blocks } = data.allMarkdownRemark.edges[0].node.frontmatter;
  const firstName = partner.firstName || partner.name.trim().split(' ')[0];
  const trialLength = partner.trialLength || '30-day';
  const price = partner.price || '$99/mo';
  const planName = partner.planName || 'Create';

  return (
    <div className={styles.impact}>
      <Container className="mt-xl">
        <Container type="mobile">
          <FullWidthRow className={styles.impact__topPricing}>
            <h4>We’ve partnered with {firstName} to offer you a {trialLength} trial of Deadline Funnel!</h4>
            <p>After your {trialLength} free trial, you’ll be billed {price} on the {planName} Plan.<br className="desktop-br" />
               You can change your plan or cancel at any time.
            </p>
            <div className="d-flex justify-content-center">
              <Button type="action" href={ctaUrl}>{partner.ctaText || 'Yes, double my trial!'}</Button>
            </div>
          </FullWidthRow>
          <FullWidthRow>
            <Title>
              We Built Deadline Funnel to <br className="desktop-br" />
              {`Help ${partner.audienceDescription || "Creators"} Make an Impact`}.
            </Title>
          </FullWidthRow>
          <div className={`${styles.impact__firstTextContainer} row`}>
            <div className="col-xl-7 col-lg-7 col-12">
              {
                blocks.slice(0, 5).map((textBlock, index) => (
                  <div key={index} className={styles.impact__textBlock} dangerouslySetInnerHTML={{ __html: textBlock }} />
                ))
              }
            </div>
            <div className="col-xl-5 col-lg-5 col-12 d-flex justify-content-center align-items-end">
              <div className={styles.impact__image} />
            </div>
          </div>
          <FullWidthRow className="mt-2">
            {
              blocks.slice(5, 8).map((textBlock, index) => (
                <div key={index} className={styles.impact__textBlock} dangerouslySetInnerHTML={{ __html: textBlock }} />
              ))
            }
          </FullWidthRow>
          <div className={`${styles.impact__examplesContainer} row`}>
            <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column justify-content-center align-items-center">
              <div className={`${styles.impact__floatingBarExample} ${styles.impact__floatingBarExample1}`} />
              <div className={`${styles.impact__floatingBarExample} ${styles.impact__floatingBarExample2}`} />
              <div className={`${styles.impact__floatingBarExample} ${styles.impact__floatingBarExample3}`} />
            </div>
            <div className="col-xl-5 col-lg-5 col-12">
              {
                blocks.slice(8, 13).map((textBlock, index) => (
                  <div key={index} className={styles.impact__textBlock} dangerouslySetInnerHTML={{ __html: textBlock }} />
                ))
              }
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <Button type="action" href={ctaUrl}>{partner.ctaText || 'Yes, double my trial!'}</Button>
          </div>
        </Container>
      </Container>
    </div>
  )
};

Impact.propTypes = {
  partner: PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    wistiaHash: PropTypes.string.isRequired,
    ctaUrl: PropTypes.string.isRequired,
    ctaText: PropTypes.string,
    audienceDescription: PropTypes.string
  }).isRequired,
  ctaUrl: PropTypes.string.isRequired
};

export default Impact;