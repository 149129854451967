import React from 'react';
import PropTypes from 'prop-types';

import styles from './Container.module.css';

const Container = ({ children, type, className }) => (
  <div className={`${type === 'normal' ? styles.container : (type === 'big' ? styles.bigContainer : styles.mobileContainer)} ${className}`}>
    {children}
  </div>
);

Container.defaultProps = {
  type: 'normal',
  className: ''
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['big', 'normal', 'mobile'])
};

export default Container;