import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import moment from 'moment';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './AnnouncementBar.module.css';

const AnnouncementBar = forwardRef(({ onChangeAnnouncementsListLength }, ref) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/announcements-list.md/" } }) {
        edges {
          node {
            frontmatter {
              enabled
              expiredAfterDays
              announcements {
                text
                id
              }
            }
          }
        }
      }
    }
  `);

  const { announcements, enabled, expiredAfterDays } = data.allMarkdownRemark.edges[0].node.frontmatter;

  const [announcementsList, updateAnnouncementsList] = useState(announcements);

  const cookies = new Cookies();

  const dismiss = barId => {
    cookies.set(`dismissed-${barId}`, 'true', {
      expires: moment().add(expiredAfterDays, 'days').toDate()
    });
    updateAnnouncementsList(
      announcementsList.map(announcement =>
        announcement.id === barId ? { ...announcement, dismissed: true } : announcement
      )
    );
  };

  useEffect(() => {
    updateAnnouncementsList(announcementsList =>
      announcementsList.map(announcement =>
        ({ ...announcement, dismissed: cookies.get(`dismissed-${announcement.id}`) === 'true' })
      )
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    onChangeAnnouncementsListLength(enabled === 'true'
      ? announcementsList.filter(announcement => !announcement.dismissed && announcement.dismissed !== undefined).length
      : 0
    )
  }, [announcementsList, onChangeAnnouncementsListLength, enabled]);

  if (enabled === 'false') return false;

  if (typeof window !== `undefined` && window.location.href.indexOf("/l/") > -1) return false;

  return (
    <div ref={ref}>
      {
        announcementsList
          .filter(announcement => !announcement.dismissed && announcement.dismissed !== undefined)
          .map(announcement =>
            <div key={announcement.id} className={styles.announcementBar}>
              <div className={styles.announcementBar__text} dangerouslySetInnerHTML={{ __html: announcement.text }} />
              <div
                className={styles.announcementBar__dismiss}
                onClick={() => dismiss(announcement.id)}
                onKeyDown={() => dismiss(announcement.id)}
                role="button"
                tabIndex={0}
              />
            </div>
          )
      }
    </div>
  );

});

AnnouncementBar.propTypes = {
  onChangeAnnouncementsListLength: PropTypes.func.isRequired
};

export default AnnouncementBar;