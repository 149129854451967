import React from 'react';

import Layout from '../../../blocks/Layout';
import SEO from '../../../blocks/SEO';
import PartnerHeader from './PartnerHeader';
import PartnerFeatures from './PartnerFeatures';
import Testimonials from './Testimonials';
import Impact from './Impact';

const Partner = ({ pathContext }) => {
  const { partner } = pathContext;

  const ctaUrl = partner.lmref ? `${partner.ctaUrl}?via=${partner.lmref}`: partner.ctaUrl;

  return (
    <Layout ctaUrl={ctaUrl}>
      <SEO title="Authentic Evergreen Marketing | Deadline Funnel" meta={[{
          name: 'robots',
          content: 'noindex',
        }]} />
      <PartnerHeader partner={partner} />
      <Impact partner={partner} ctaUrl={ctaUrl} />
      <PartnerFeatures ctaUrl={ctaUrl} />
      <Testimonials />
    </Layout>
  )
};

export default Partner;
